import {
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material"

const Form = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="IP Inicial"
          variant="standard"
          fullWidth
          margin="dense"
          helperText="Primeiro IP do bloco privado"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Bloco público"
          variant="standard"
          fullWidth
          margin="dense"
          helperText="Rede/Máscara"
        />
      </Grid>
      <Grid item xs={4}>
        <InputLabel for="ratio">1 para?</InputLabel>
        <Select
          id="ratio"
          variant="standard"
          label="1 para?"
          fullWidth
          defaultValue={64}
        >
          <MenuItem value={32}>32</MenuItem>
          <MenuItem value={64}>64</MenuItem>
          <MenuItem value={128}>128</MenuItem>
        </Select>
        <FormHelperText>Quantidade de clientes por IP público</FormHelperText>
      </Grid>
      <Grid item xs={4}>
        <InputLabel for="if-uplink">Interface Uplink</InputLabel>
        <Select id="if-uplink" variant="standard" label="Interface Uplink" fullWidth defaultValue="none">
          <MenuItem value="none">Não informar</MenuItem>
          <MenuItem value="if-name">Nome da interface</MenuItem>
          <MenuItem value="if-list">Interface list</MenuItem>
        </Select>

      </Grid>
      <Grid item xs={4}>
        <TextField label="Nome da interface" variant="standard" fullWidth margin="dense" />
      </Grid>
      <Grid item xs={4}>
        <InputLabel for="blackhole">IPs na blackhole</InputLabel>
        <Select id="blackhole" variant="standard" label="IPs na blackhole" fullWidth defaultValue="yes">
          <MenuItem value="yes">Sim</MenuItem>
          <MenuItem value="no">Não</MenuItem>
        </Select>
        <FormHelperText>Evita ataques</FormHelperText>
      </Grid>
      <Grid item xs={4}>
        <TextField label="Nome da interface dos IPs públicos" variant="standard" fullWidth margin="dense" />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Sequencial do nome da chain"
          variant="standard"
          fullWidth
          margin="dense"
          helperText="CGNAT-0, CGNAT-1, CGNAT-2..."
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Protocolo</FormLabel>
        <RadioGroup row>
          <FormControlLabel value="tcp_udp" control={<Radio />} label="TCP/UDP" />
          <FormControlLabel value="tcp" control={<Radio />} label="Apenas TCP" />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained">Gerar script</Button>
      </Grid>
    </Grid>
  );
}

export default Form;