import {
  AppBar,
  Container,
  Toolbar,
  Typography
} from "@mui/material";
import Form from "./components/Form";

function App() {
  return (
    <Container>
      <AppBar>
        <Toolbar>
          <Container>
            <Typography variant="h6">L3 Route - CGNAT Generator</Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Form />
    </Container>
  );
}

export default App;
